@import "common/variables";

::ng-deep {
  .actions-popover {

    .mde-popover-content {
      background-color: $bgSecondary;
      width: auto;
      height: auto;
      box-shadow: 0 2px 4px $blackColorOpacity010;
      border: 1px solid $borderLight;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 !important;
      margin: 4px 0 0 !important;
      border-radius: 8px;

      div {
        width: 100%;
        padding: 0;

        div {
          width: 100%;
          padding: 8px;
          margin-bottom: 0 !important;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 4px;

          mat-icon {
            width: 18px !important;
            height: 18px !important;
            line-height: 18px !important;
            font-size: 18px !important;
            color: $textPrimary90;
          }

          &:last-child {
            margin-bottom: 0;
          }

          &:hover {
            background-color: $bgTertiaryTransparent;
          }

          &.disabled-popover-action {
            cursor: default;

            &:hover {
              background-color: inherit;
            }

            mat-icon {
              color: $textSecondary80
            }

            a {
              color: $textSecondary80;
              cursor: default;
              pointer-events: none;
            }
          }
        }
      }
    }

    .mde-popover-direction-arrow {
      border-bottom-color: $borderMedium !important;
      left: 4px !important;
      display: none !important;
    }

    &.mde-popover-before .mde-popover-direction-arrow {
      left: auto !important;
    }

    a {
      cursor: pointer;
      font-size: 13px;
      font-weight: 600;
      line-height: 22px;
      text-align: left;
      color: $textPrimary;
      text-decoration: none;
    }
  }
}
