@import "common/variables";

.elearnio-mat-tree {
  mat-tree-node {
    border: 1px solid rgba(189, 193, 201, 0.5019607843);
    border-top: 0;
    min-height: 42px;
    height: 42px;

    .header {
      color: $textSecondary;
      border-right: 1px solid $borderMedium;
      height: 100%;
    }
  }

  .node {
    color: $interactive;
    width: 100%;
    align-items: center;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    height: 42px;

    &:hover {
      background-color: $bgSecondary;
    }
  }

  ::ng-deep {
    .indicator.mat-icon {
      cursor: pointer;
      height: 16px;
      width: 16px;
      font-size: 16px;
      color: $interactive;
      margin-right: 8px;
    }

    .inverted {
      transform: rotate(180deg);
    }
  }

  &.with-fixed-header {
    .tree-header {
      position: sticky;
      top: 0;
      z-index: 2;
    }
  }

  .tree-header {
    @extend .mat-header-cell;
    font-size: 16px;
    color: $textSecondary;
    display: flex;
    border: 1px solid rgba(189, 193, 201, 0.5019607843);
    border-top: 0;

    .header {
      vertical-align: bottom;
      display: flex;
      align-items: flex-end;
      border-top: 1px solid $borderMedium;
      border-right: 1px solid $borderMedium;
      background-color: $bgTertiary;
      color: $textSecondary;
      text-align: center;
    }

    .column-header {
      font-size: 13px;
      font-weight: 600;
      line-height: 22px;
    }
  }

  .header {
    @extend .mat-header-cell;
    font-size: 13px;
    font-weight: 600;
    line-height: 22px;
    padding: 10px 12px;
    text-align: center;
    line-height: 22px;

    &:first-child {
      padding-left: 16px;
    }

    &:last-child {
      border-right: none;
    }

    .center {
      justify-content: center;
    }
  }
}
