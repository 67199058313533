@import "common/variables";

::ng-deep mat-form-field {
  margin-bottom: 5px;
  font-size: 11px !important;

  &.mat-form-field-appearance-outline {

    &.no-padding-bottom {
      .mat-form-field-wrapper {
        margin: 0;
        padding: 0;

        .mat-form-field-infix  {
          height: 42px;
        }
      }
    }

    .mat-form-field-infix {
      padding: 0.6em 0 !important;
    }

    .mat-form-field-outline-start {
      border-radius: 8px 0 0 8px;
    }

    .mat-form-field-outline-end {
      border-radius: 0 8px 8px 0;
    }

    input, textarea, mat-select, mat-option {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $textPrimary;
    }

    label.mat-form-field-label {
      font-size: 14px;
      line-height: 21px;
      top: 1.1em;
    }

    &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
      transform: translateY(-1.3em) scale(0.75);
      top: 1.3em;
    }

    .mat-select-arrow-wrapper {
      transform: translateY(-10%);
    }

    .mat-form-field-prefix, .mat-form-field-suffix {
      top: 0;

      mat-datepicker-toggle {
        .mat-icon-button {
          width: 16px !important;
        }
      }
    }
  }

  &.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline,
  &.mat-form-field.mat-focused.mat-primary .mat-select-arrow,
  &.mat-form-field.mat-focused .mat-form-field-label {
    color: $interactive;
  }

  &.mat-form-field-invalid {
    color: $error;
  }

  &.mat-form-field-warning.mat-form-field-outline-warning {
    .mat-form-field-outline {
      color: $warning !important;
    }

    .mat-form-field-appearance-outline {
      .mat-form-field-outline {
        color: $warning !important;
      }
    }
  }

  mat-error {
    line-height: 14px;
  }

  &.disable {
    pointer-events: none;

    input,
    mat-label {
      color: $textSecondary80;
    }

    .mat-select-arrow {
      color: rgba(var(--palette-foreground-disabled-text), var(--palette-foreground-disabled-text-alpha, 1));
    }
  }

  &.disable-background {
    pointer-events: none;

    .mat-form-field-outline-start {
      background-color: $bgSecondaryTransparent !important;
    }
    
    .mat-form-field-outline-gap {
      background-color: $bgSecondaryTransparent !important;
    }
    
    .mat-form-field-outline-end {
      background-color: $bgSecondaryTransparent !important;
    }
  }
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $interactive;
}

.mat-pseudo-checkbox {
  color: $interactive;
}

.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: $interactive;
}
