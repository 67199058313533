@import "common/variables";

.mat-dialog-container {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  padding: 32px !important;

  .mat-dialog-actions {
    padding: 0;
    min-height: unset;
    margin-bottom: 0;
  }
}

.elearnio-mat-dialog-full-panel-class {
  .mat-dialog-container {
    padding: 0 !important;;
    border-radius: 0 !important;
    background-color: $bgSecondary;
  }

  .elearnio-mat-dialog-full-panel-class-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    height: 68px;
    background-color: $whiteColor;
    border-bottom: 1px solid $borderLight;

    &-header-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

    }

    &-header-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
    }
  }
}

@media screen and (max-width: calc($screen-lg - 1px)) {
  .elearnio-mat-dialog-panel-class {
    width: 100%;
    max-width: 100vw !important;
    margin: auto 0 0;
  }
}

@media (min-width: $screen-lg) {
  .elearnio-mat-dialog-panel-class {
    width: 600px !important;
  }

  .mat-dialog-container {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
  }
}
