@import "variables";

// My overrides & some global definitions
* {
  box-sizing: border-box;
}

html,
body {
  height: 100vh;
  height: -webkit-fill-available;
  max-height: 100vh;
  max-height: -webkit-fill-available;
  font-size: 14px;
  overflow-x: hidden;
  overflow-y: hidden;
  color: $textPrimary;
}

.elearnio-input {
  height: 36px;
  font-size: 14px;
  font-weight: 400;
  color: $textPrimary;
}

.table-row {
  height: 40px;
  font-size: 14px;
  border-color: $bgSecondary;
  border-width: 1px;
}

.mat-elearnio-button {
  font-family: "Open Sans", sans-serif !important;
  height: 36px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 0 16px !important;

  &.font-14 {
    font-size: 14px !important;
  }

  &.no-border {
    border: none !important;
  }

  &.no-padding {
    padding: 0 !important;
  }

  &.small-icon-button {
    min-width: 36px;
  }

  &.with-icon-margin {
    mat-icon {
      margin-right: 4px;
    }
  }

  &.inactive,
  &.mat-button-disabled {
    opacity: 0.3 !important;
  }

  .mat-button-wrapper {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .mat-icon {
    font-size: 16px !important;
    height: 16px !important;
    width: 16px !important;
  }

  &.mat-stroked-button {
    border-radius: 8px !important;
  }
}

.mat-icon {
  &.size-m {
    font-size: 24px !important;
    height: 24px !important;
    width: 24px !important;
    min-width: 24px !important;
    min-height: 24px !important;
    line-height: 24px !important;
  }

  &.size-sm {
    font-size: 20px !important;
    height: 20px !important;
    width: 20px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    line-height: 20px !important;
  }

  &.size-s {
    font-size: 18px !important;
    height: 18px !important;
    width: 18px !important;
    min-width: 18px !important;
    min-height: 18px !important;
    line-height: 18px !important;
  }
}

// Buttons
.mat-yellow {
  @extend .mat-elearnio-button;
  background-color: $mainButton;
  font-family: "Open Sans", sans-serif;
  box-shadow: none !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: $mainButtonTextColor;
  height: 36px;
  border-radius: 8px;
  padding: 0 24px;

  &.filled {
    background-color: $mainButton;
  }

  &.mat-button-disabled {
    color: $mainButtonTextColor80 !important;
    background: $mainButton30 !important;
    text-align: center;
  }

  &.slim {
    height: 24px !important;
    font-size: 14px !important;
    line-height: 24px;
  }

  &.touch {
    height: 44px !important;
    font-size: 14px !important;
    font-weight: 600;
    align-items: center;
  }
}

.mat-white {
  @extend .mat-yellow;
  background-color: $whiteColor;
  font-family: "Open Sans", sans-serif;
  color: $interactive;
  border: 1px solid $interactive50;

  .mat-button-wrapper {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .mat-icon {
    font-size: 16px;
    height: 16px;
    width: 16px;
  }

  &.mat-button-disabled {
    color: $interactive !important;
    background: $whiteColorOpacity030 !important;
  }

  &.mat-stroked-button {
    border-radius: 8px;
  }

  &.filled {
    background-color: $whiteColor;
  }

  &.no-border {
    border: none;
  }
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: $interactive50;
}

.mat-darkblue {
  @extend .mat-yellow;

  &.mat-stroked-button {
    background-color: transparent;
    padding: 0 24px;

    mat-icon {
      color: $interactive;
    }
  }

  &.mat-button-disabled {
    color: $interactive !important;
    background: transparent !important;
  }

  &.filled {
    background-color: $interactive;
    color: $whiteColor;

    &.mat-button-disabled {
      color: $whiteColor !important;
      background: $interactive !important;
    }

    mat-icon {
      color: $whiteColor;
    }
  }

  color: $interactive;
}

a {
  font-size: 14px;
  font-weight: 600;
  color: $interactive;
}

ul,
p {
  margin: 0;
}

a:focus,
button:focus,
input:focus,
textarea:focus,
select:focus,
.menu-item:focus,
.router-link:focus {
  outline: 1px solid transparent;
}

button {
  border: none;
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

img {
  display: block;
}

.main-layout {
  flex: 1;
  height: calc(100% - 64px);
  width: 100vw;
  position: relative;
  z-index: 2;

  &.mobile {
    height: calc(100% - 48px);
  }

  &.full-height {
    height: 100%;
  }
}

body.is-modal .main-layout {
  overflow: visible;

  mat-drawer-container mat-drawer-content {
    overflow: visible;
  }
}

.router-link-activated {
  font-weight: 700 !important;
}

.h5p-editor-iframe {
  width: 100%;
  height: 100%;
}

.breadcrumb {
  font-size: 14px;
  font-weight: 400;
  color: $textSecondary;

  &.active {
    font-weight: 600;
  }
}

#userback_button_container.userback-button-container .userback-button {
  display: none !important;
}

// Dialogs
::ng-deep {
  .mat-form-field-subscript-wrapper {
    font-size: 12px !important;
  }

  .mat-date-range-input-start-wrapper,
  .mat-date-range-input-end-wrapper {
    input,
    span {
      margin-top: -0.0625em;
    }
  }
}

h1.mat-dialog-title {
  font-family: "Open Sans", sans-serif;
  font-size: 28px;
  font-weight: 700;
}

.mat-dialog-content {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: $textSecondary;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}

.mat-tooltip {
  font-size: 12px;
}

.mat-select-value {
  color: $textPrimary !important;
}

.author-editor {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;

  .author-editor-count {
    padding: 0 8px;
    border-radius: 8px;
    border: solid 1px $borderMedium;
  }
}

@import "shared/elearnio-mat-slide-toggle";
@import "shared/elearnio-mat-form-field";
@import "shared/elearnio-mat-radio-group";
@import "shared/elearnio-mat-checkbox";
@import "shared/actions-popover";
@import "shared/elearnio-mat-table";
@import "shared/elearnio-mat-tree";
@import "shared/elearnio-mat-tabs";
@import "shared/elearnio-mat-dialog";
