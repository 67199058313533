@import "common/variables";

::ng-deep mat-checkbox.elearnio-checkbox {

  .mat-checkbox-ripple .mat-ripple-element,
  &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $interactive !important;
  }

  &.mat-checkbox-disabled.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $textSecondary80 !important;
  }
}
