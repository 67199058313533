// Colors new
$primary: rgb(var(--palette-primary-500));
$primary50: rgb(var(--palette-primary-500), 0.5);;
$interactive: rgb(var(--palette-accent-500));
$interactive50: rgb(var(--palette-accent-500), 0.5);
$mainButton: rgb(var(--palette-background-raised-button));
$mainButton30: rgb(var(--palette-background-raised-button), 0.3);
$mainButtonTextColor: rgb(var(--palette-accent-50));
$mainButtonTextColor80: rgb(var(--palette-accent-50), 0.8);

// Text Colors
$textPrimary: #2F3238;
$textPrimary90: #2F3238E5;
$textPrimary80: #2F3238CC; // for all input placeholders
$textSecondary: #6B6D72;
$textSecondary80: #6B6D72CC; // for text color when it is disabled
$textDarkest: #181B22;

// Background colors
$bgPrimary: #FFFFFF;
$bgSecondary: #FAFAFB;
$bgSecondaryTransparent: #BDC1C91A;
$bgTertiary: #EFF0F2;
$bgTertiaryTransparent: #BDC1C933;
$bgQuaternary: #DEE0E4;
$bgQuaternaryTransparent: #BDC1C980;
$backdrop: #46494FCC;

// Border colors
$borderLight: #BDC1C94D;
$borderMedium: #BDC1C980;
$borderDark: #BDC1C9;

// Utility Colors
$notifications: #F3835A;
$notificationsText: #A2603B;
$notificationTagColor: #FFDDCB;
$feedbackNotificationText: #804530;
$feedbackNotificationBackground: #FEF4F0;
$feedbackNotificationTag: #FBDACD;
$info: #006699;
$infoBackground: #F2FBFF;
$infoTag: #E0F5FF;
$warning: #FEC22F;
$warningBackground: #FFF9EA;
$warningTag: #FEECC0;
$error: #CC0B2A;
$errorText: #8F081D;
$errorTag: #FBCDD5;
$errorBackground: #FBEBEE;
$success: #4EB58B;
$successText: #377F61;
$successBackground: #F1F9F6;
$successTag: #C9E8DC;
$notificationTag: #FFD5BE;
$interactiveElementsColor: #3D757F;
$blockColor: #E0F2E6;

// Training Schedule Colors
$calendarMeeting: #EEE1FF;


// Colors
$whiteColor: #fff;
$whiteColorOpacity50: rgba(255, 255, 255, 0.5);
$yellowColor: #FEC22F;
$yellowColorLightOpacity40: rgba(254, 194, 47, 0.4);
$orangeColor: #F3835A;
$greenColorOpacity50: #59dd7d80;
$blackColor: rgba(0, 0, 0, 0);
$blackColorOpacity004: rgba(0, 0, 0, 0.04);
$blackColorOpacity006: rgba(0, 0, 0, 0.06);
$blackColorOpacity007: rgba(0, 0, 0, 0.07);
$blackColorOpacity010: rgba(0, 0, 0, 0.1);
$blackColorOpacity012: rgba(0, 0, 0, 0.12);
$blackColorOpacity014: rgba(0, 0, 0, 0.14);
$blackColorOpacity016: rgba(0, 0, 0, 0.16);
$blackColorOpacity020: rgba(0, 0, 0, 0.20);
$blackColorOpacity057: rgba(0, 0, 0, 0.57);
$blackColorOpacity065: rgba(0, 0, 0, 0.65);
$whiteColorOpacity030: rgba(255, 255, 255, 0.3);
$whiteColorOpacity045: rgba(255, 255, 255, 0.459);
$blackGrayColorOpacity20: rgba(128, 128, 128, 0.2);
$whiteGrayOpacity50: rgba(225, 219, 250, 0.5);
$brownColorOpacity30: rgba(254, 194, 47, 0.3);
$lightBlueOpacity54: rgba(62, 120, 130, 0.54);
$lightOrangeOpacity40: rgba(254, 150, 92, 0.4);
$iconNeutral: rgba(47, 50, 56, 0.9);
$goldColor: $yellowColor;
$silverColor: $borderDark;
$bronzeColor: $notificationsText;

//
$main-body-bg-color: $bgSecondary;

// Fonts
$font-default: 'Open sans', serif;

// Other
$headerHeight: 64px;
$headerMobileHeight: 48px;

// Breakpoints
$screen-xsm: 360px;
$screen-sm: 600px;
$screen-md: 960px;
$screen-lg: 1280px;
$screen-xl: 1600px;
$screen-xxl: 1920px;
