@import "common/variables";

::ng-deep mat-radio-group.elearnio-mat-radio {

  .mat-radio-container {
    width: 18px !important;
    height: 18px !important;
  }

  .mat-radio-label-content {
    padding-left: 12px !important;
    line-height: 22px !important;
  }

  .mat-radio-button.mat-accent:not(.mat-disabled) {

    .mat-radio-inner-circle {
      background-color: $interactive;
      top: -1px;
      left: -1px;
    }

    .mat-radio-outer-circle {
      border-color: $interactive;
      width: 18px !important;
      height: 18px !important;
      border-width: 1px;
    }
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle,
  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
  .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: $interactive;
  }
}
